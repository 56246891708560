import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { getProduct, getRelated } from "../functions/product";
import SingleProduct from "../components/cards/SingleProduct";
import ProductCard from "../components/cards/ProductCard";
import BrochureModal from "../components/modals/BrochureModal";
import ListModal from "../components/modals/ListModal";
import { DownloadOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Link, useLocation } from "react-router-dom";
import ErrorBoundary from "../components/error/ErrorBoundary";

const Product = ({ match }) => {
  const [product, setProduct] = useState({});
  const [related, setRelated] = useState([]);

  const { slug } = match.params;
  const location = useLocation();

  useEffect(() => {
    loadSingleProduct();
  }, [slug, location.state]); // Add location.state to the dependency array

  const loadSingleProduct = () => {
    getProduct(slug).then((res) => {
      setProduct(res.data);
      getRelated(res.data._id).then((res) => setRelated(res.data));
    });
  };

  return (
    <div className="container-fluid p-0">
      {/* Wrap the relevant part with the ErrorBoundary component */}
      <ErrorBoundary>
        <div className="row mx-0 angle-product"></div>
        <div className="row mt-n5 pt-4">
          <SingleProduct product={product} />
        </div>
        {product.brochure?.url || product.datasheet ? (
          <div className="container">
            <div className="col text-center pt-5 pb-5">
              <h2 className="blue">More Information</h2>
            </div>
            <div className="row pb-5 text-center justify-content-center">
              {product.brochure?.url && (
                <div
                  className={classNames("m-1 bg-white", {
                    "col-md-3": product.datasheet,
                    "col-md-4": !product.datasheet,
                  })}
                >
                  <BrochureModal product={product} />
                </div>
              )}
              {product.datasheet && (
                <div
                  className={classNames("m-1 bg-white", {
                    "col-md-3": product.brochure,
                    "col-md-4": !product.brochure,
                  })}
                >
                  <a
                    href={product.datasheet.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h6 className="pt-5">APPLICATIONS LIST</h6>
                    <br />
                    <CloudDownloadOutlined
                      className="blue"
                      style={{ fontSize: "800%" }}
                    />
                    <p className="pt-5 pb-3 text-dark">
                      Click to view full applications list.
                    </p>
                  </a>
                </div>
              )}
            </div>
          </div>
        ) : null}
        <div
          className={classNames("container-fluid", {
            "bg-white": !(product.brochure?.url || product.datasheet),
            bgstill: product.brochure?.url || product.datasheet,
          })}
        >
          <div className="row">
            <div className="col text-center pt-5 pb-3">
              <h3>Related Items</h3>
            </div>
          </div>
          <div className="container">
            <div className="row pb-5 justify-content-md-center">
              {related.length ? (
                related.map((r) => (
                  <div key={r._id} className="col-md-3">
                    <ProductCard product={r} />
                  </div>
                ))
              ) : (
                <div className="text-center col">No Items Available</div>
              )}
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default Product;
